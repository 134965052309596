import { useState, useEffect, useRef } from 'react'
import { Button, Col, Row, InputGroup, Tabs, Tab, Dropdown } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose, faUserMinus, faMessage, faUserPlus, faEllipsisVertical, faBan, faCheckCircle, faTimesCircle, faAward, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../hooks/useInitialization.js';
import { useAppState } from '../../hooks/useApp'
import { AnimatePresence, motion } from "framer-motion"
import { TextWithLineBreaks, cleanUpSpecialChars, useInfiniteScroll } from "../../components/Functions"
import PageLoader from '../../components/PageLoader.js'
import { NetworkingConfirmationModal } from '../../components/Modals.js';
import useAuth from '../../hooks/useAuth.js';

const Networking = () => {
    const { dataApplication } = useGlobalState();
    const { scrollContainerRef, socket, totalContacts, updatePageDataNetworking, setUpdatePageDataNetworking, setTotalContacts } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const tabKeyRef = useRef('all')
    const [tabKey, setTabKey] = useState('all');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)
    const tabSwitching = useRef(false)
    const { user } = useAuth()
    const [pageFetching, setPageFetching] = useState(false)
    const pagination = useRef(0)
    const limit = 25;

    const handleTabSwitch = (tab) => {
        if (tabKeyRef.current === tab) {
            return
        }
        if (tabSwitching.current === false) {
            setTabKey(tab);
            setPageData()
            setPageDataComplete()
            setPageFetching(false)
            pagination.current = 0
        }
    }

    //Search function
    const search = (data) => {
        // eslint-disable-next-line eqeqeq
        if (searchQuery.trim() != '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
            let newData = data.filter(element => {
                let searchableFields = []
                dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.forEach(inputID => {
                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                    if (element.json && element.json[field.inputID]) {
                        searchableFields.push(cleanUpSpecialChars(element.json[field.inputID].value.toLowerCase()))
                    }
                })
                return searchableFields.some(field => field.includes(lowercaseQuery));
            })
            return newData
        } else {
            return data
        }
    }

    const fetchData = (addItems) => {
        socket.emit('pageDataNetworking', { page: 'networking', tab: tabKey, id: id, offset: pagination.current * limit, addItems: addItems });
    }

    //Infinity scroll handler
    useInfiniteScroll(scrollContainerRef, pageFetching, setPageFetching, pagination, fetchData);

    //Update item handler for most actions
    useEffect(() => {
        if (socket) {
            if (Object.keys(updatePageDataNetworking.data).length > 0) {
                updateCurrentData(
                    updatePageDataNetworking.data.newStatus,
                    parseInt(updatePageDataNetworking.data.id),
                    pageData,
                    setPageData,
                    pageDataComplete,
                    setPageDataComplete,
                    setTotalContacts,
                    pageDataItem,
                    setPageDataItem,
                    updatePageDataNetworking.data.origin,
                    updatePageDataNetworking.data.other_user_relationship_status
                );
                setUpdatePageDataNetworking(prevState => ({
                    ...prevState,
                    data: {}
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePageDataNetworking.count])

    useEffect(() => {
        if (pageData) {
            let newData = search(pageDataComplete)
            setPageData(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    useEffect(() => {
        if (socket && !pageDataItem) {
            tabSwitching.current = true
            tabKeyRef.current = tabKey

            socket.on('pageDataNetworking', (response) => {
                if (id && response.user_id) {
                    setPageDataItem(response);
                } else {
                    setTimeout(() => {
                        let updatedPageDataComplete;
                        console.log(response.data)
                        if (response.addItems === true) {
                            setTimeout(() => {
                                if (response.data.length > 0) {
                                    setPageDataComplete(prevPageDataComplete => {
                                        if (Array.isArray(prevPageDataComplete)) {
                                            updatedPageDataComplete = [...prevPageDataComplete, ...response.data];
                                            console.log(updatedPageDataComplete)
                                            const searchedData = search(updatedPageDataComplete);
                                            console.log(searchedData)
                                            setPageData(searchedData);
                                            return updatedPageDataComplete
                                        } else {
                                            updatedPageDataComplete = [...response.data];
                                            const searchedData = search(updatedPageDataComplete);
                                            setPageData(searchedData);
                                            return prevPageDataComplete
                                        }
                                    });
                                    setPageFetching(false)
                                    pagination.current += 1;
                                } else {
                                    setPageFetching('noFetching')
                                }
                            }, 250);
                        } else {
                            //Normal fetch
                            setPageData(response.data);
                            setPageDataComplete(response.data)
                            //Check if infinity scroll is neccessary and set it up
                            if (pagination.current === 0 && response.data.length >= limit) {
                                pagination.current += 1;
                            } else {
                                setPageFetching('noFetching')
                            }
                        }
                        if (user.type === 0) {
                            setTotalContacts(response.total);
                        }
                        //Tab debounce
                        tabSwitching.current = false;
                    }, 250);
                }
            });

            fetchData(false)

            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataNetworking');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, id, tabKey]);

    return (
        <motion.div className="container networkingContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <NetworkingConfirmationModal pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} setTotalContacts={setTotalContacts}></NetworkingConfirmationModal>
            <AnimatePresence mode="wait" initial={false}>
                {id > 0 ? (
                    <motion.div key={'itemA'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <header className="pageHeader">
                            <Button type="button" className="backButton" onClick={(() => navigate(`/participantes${location.search}`))}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Button>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ms-2">
                                    <li className="breadcrumb-item"><h5 onClick={(() => navigate(`/participantes${location.search}`))}>Participantes</h5></li>
                                    <li className="breadcrumb-item"><h5>Detalhes</h5></li>
                                </ol>
                            </nav>
                        </header>
                        {(!pageDataItem) ? (
                            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                            </motion.div>
                        ) : (
                            <>
                                <div className='networkingItemHeader'>
                                    <div className="networkingRankingLeft">
                                        <FontAwesomeIcon icon={faTrophy} className="me-2" />{pageDataItem.rank_position}º
                                    </div>
                                    <div className="networkingRankingRight">
                                        <FontAwesomeIcon icon={faAward} className="ms-4 me-2" />{pageDataItem.total_value} pts
                                    </div>
                                    <label>
                                        <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + pageDataItem.image} alt="User avatar"></img>
                                    </label>
                                </div>
                                <Row className="networkingItemBody">
                                    <Col sm={12} className="mb-3">
                                        {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                            if (pageDataItem.json && pageDataItem.json[field.inputID]) {
                                                if (index === 0) {
                                                    return (
                                                        <h6 className="mb-0" key={'h5' + field.inputID}>{pageDataItem.json[field.inputID].value}</h6>
                                                    )
                                                } else {
                                                    return (
                                                        <p className="mt-1 mb-0 small" key={'small' + field.inputID}>{pageDataItem.json[field.inputID].value}</p>
                                                    )
                                                }
                                            }
                                            return null; // Handle cases where the field is not found (optional)
                                        })}
                                    </Col>
                                    {user.type === 0 ?
                                        <Col sm={12} className="mb-3 d-flex justify-content-center">
                                            <UserRelationshipButtonItem item={pageDataItem} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem}></UserRelationshipButtonItem>
                                        </Col> : ''}
                                </Row>
                                <Tabs
                                    defaultActiveKey="info"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mt-4 mb-3"
                                >
                                    <Tab eventKey="info" title="Sobre">
                                        {pageDataItem.relationship_status === 'friend' || pageDataItem.collected === 1 ? (
                                            <>
                                                {Object.entries(pageDataItem.json).map(([key, { label, value }]) => {
                                                    // eslint-disable-next-line eqeqeq
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID == key);
                                                    if (field && field.displayField === true) {
                                                        return (
                                                            <Col sm={12} className="mt-3" key={'modalDataFields' + key}>
                                                                <h6 className="fw-bold">{label}</h6>
                                                                <p><TextWithLineBreaks text={value}></TextWithLineBreaks></p>
                                                            </Col>
                                                        );
                                                    } else {
                                                        return ""
                                                    }
                                                })}
                                            </>
                                        ) : (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Adicione esse contato para visualizar suas informações.</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Tab>
                                    <Tab eventKey="publications" title="Publicações">
                                        {pageDataItem.relationship_status === 'friend' || pageDataItem.collected === 1 ? (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Nenhuma publicação encontrada</p>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Adicione esse contato para visualizar suas informações.</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Tab>
                                </Tabs>
                            </>
                        )}
                    </motion.div>
                ) : (
                    <motion.div key={'itemB'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        {user.type === 1 ?
                            <header className="pageHeader">
                                <Button type="button" className="backButton" onClick={(() => navigate(`/`))}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </Button>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb ms-2">
                                        <li className="breadcrumb-item"><h5>Participantes</h5></li>
                                    </ol>
                                </nav>
                            </header>
                            :
                            <div className="header-wrapper my-3">
                                <div className="tab-wrapper">
                                    <motion.div
                                        className="switch-background"
                                        initial={false}
                                        animate={{ x: tabKey === 'all' ? 0 : tabKey === 'contacts' ? '100%' : '200%' }}
                                        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                                    />
                                    <button
                                        className={`tab-button ${tabKey === 'all' ? 'active' : ''}`}
                                        onClick={() => handleTabSwitch('all')}
                                    >
                                        Todos ({totalContacts.total_available})
                                    </button>
                                    <button
                                        className={`tab-button ${tabKey === 'contacts' ? 'active' : ''}`}
                                        onClick={() => handleTabSwitch('contacts')}
                                    >
                                        Contatos ({totalContacts.total_contacts})
                                    </button>
                                    <button
                                        className={`tab-button ${tabKey === 'invites' ? 'active' : ''}`}
                                        onClick={() => handleTabSwitch('invites')}
                                    >
                                        Convites ({totalContacts.total_invites})
                                    </button>
                                </div>
                            </div>}
                        <div className="d-flex mb-3">
                            <InputGroup className="mb-3">
                                <motion.input
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                    exit={{ opacity: 0 }}
                                    className="form-control"
                                    placeholder="Pesquisar..."
                                    aria-label="search"
                                    aria-describedby="search"
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                                <AnimatePresence>
                                    {searchQuery !== '' && (
                                        <motion.div
                                            layout
                                            id="search"
                                            className="input-group-text"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                            exit={{ opacity: 0 }}
                                        >
                                            <FontAwesomeIcon className="primaryColorT" icon={faClose} onClick={() => setSearchQuery('')} />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </InputGroup>
                            {/* <div>
                                <Button type="button" className="backButton btn-sm ms-2" onClick={() => ("")}>
                                    <FontAwesomeIcon icon={faSliders} style={{ transform: 'rotate(90deg)' }} size={"lg"} />
                                </Button>
                            </div> */}
                        </div>
                        <AnimatePresence mode="wait" initial={false}>
                            <PageHandler key={tabKey} tabKey={tabKey} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts}></PageHandler>
                        </AnimatePresence>
                        {pageFetching !== 'noFetching' && <motion.div className={`container p-0 pageLoaderContainer ${pageFetching === true ? 'opacity-1' : 'opacity-0'}`} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                            <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                        </motion.div>}
                    </motion.div>
                )
                }
            </AnimatePresence >
        </motion.div >
    )
}

const PageHandler = ({ tabKey, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {

    switch (tabKey) {
        case 'invites':
            return (
                <Invites pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} />
            )
        case 'contacts':
            return (
                <Contacts pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} />
            )

        default:
            return (
                <AllContacts pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} />
            )
    }
}

const AllContacts = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row>
                            {pageData.map((item, index) => (
                                <Col md={6} sm={12} lg={4} className="mb-3 networking-card" key={'cardSubItem' + index}>
                                    <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} origin={'networking'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum participante encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

const Contacts = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row>
                            {pageData.map((item, index) => (
                                <Col md={6} sm={12} lg={4} className="mb-3 networking-card" key={'cardSubItem' + index}>
                                    <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} origin={'networking'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum contato encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

const Invites = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row>
                            {pageData.map((item, index) => (
                                <Col md={6} sm={12} lg={4} className="mb-3 networking-card" key={'cardSubItem' + index}>
                                    <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} origin={'networking'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum convite encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

export const ParticipantsCard = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, origin }) => {
    const { dataApplication } = useGlobalState();
    const { dispatch } = useAppState()
    const navigate = useNavigate();
    const { user } = useAuth()
    const location = useLocation();

    const handleCardClick = (item) => {
        if (origin === 'networking') {
            navigate(`/participantes/${item.user_id}${location.search}`, { state: { item: item } });
        } else {
            navigate(`/participantes/${item.user_id}${location.search}`);
        }
        dispatch({ type: 'updatePageDataItem' });
    }

    return (
        <div className="card pointer" onClick={() => handleCardClick(item)}>
            <div className="card-body horizontally-centered">
                <div className="vertically-centered">
                    <img className="networkingProfile" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + '/' + item.image} alt="speakerImage" />
                </div>
                <div className="networkingFields">
                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                        if (item.json && item.json[field.inputID]) {
                            if (index === 0) {
                                return (
                                    <h6 key={'networkingFields' + index} className="card-title mb-0 fw-bold">{item.json[field.inputID].value}</h6>
                                )
                            } else {
                                return (
                                    <p className="mt-1 mb-0 small" key={'networkingFields' + index}>{item.json[field.inputID].value}</p>
                                )
                            }
                        }
                        return null; // Handle cases where the field is not found (optional)
                    })}
                </div>
                {user.type === 0 ?
                    <UserRelationshipButton item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts}></UserRelationshipButton>
                    : ''}
            </div>
        </div>
    )
}

const UserRelationshipButtonItem = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem }) => {
    const { dataApplication } = useGlobalState();
    const { socket, setModalConfirmation, setConfirmationProps } = useAppState()

    const handleDropdownClick = (e) => {
        e.stopPropagation()
    };

    const confirmationHandler = (type, e, status, user_id) => {
        e.stopPropagation()
        let props = {}
        if (type === 'remove') {
            props.title = "Remover usuário"
            props.description = "Deseja remover esse usuário da sua lista de contatos? Você não poderá mais interagir com ele"
        } else if (type === 'block') {
            props.title = "Bloquear usuário"
            props.description = "Deseja bloquear esse usuário? Você será removido da lista de contatos dele"
        } else if (type === 'unblock') {
            props.title = "Desbloquear usuário"
            props.description = "Deseja desbloquear esse usuário? Você voltará a ficar disponível na lista de contatos dele"
        }
        setConfirmationProps({ 'type': type, 'title': props.title, 'description': props.description, 'user_id': user_id, 'status': status })
        setModalConfirmation(true)
    };

    switch (item.relationship_status) {
        case 'friend':
            return (
                <>
                    <div className="userRelationshipButton">
                        <Button variant="primary" className="me-2"><FontAwesomeIcon size={'lg'} icon={faMessage} /></Button>
                    </div>
                    <div onClick={handleDropdownClick}>
                        <Dropdown>
                            <Dropdown.Toggle className="userRelationshipButton">
                                <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => confirmationHandler('remove', e, 0, item.user_id)}>Remover contato</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => confirmationHandler('block', e, 0, item.user_id)}>Bloquear contato</Dropdown.Item>
                                <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )
        case 'blocked':
            return (
                <>
                    <div className="userRelationshipButton">
                        <Button variant="secondary" className="me-2" onClick={(e) => confirmationHandler('unblock', e, 1, item.user_id)}>
                            <FontAwesomeIcon className="me-2" icon={faBan} />
                            Contato bloqueado
                        </Button>
                    </div>
                    <div onClick={handleDropdownClick}>
                        <Dropdown>
                            <Dropdown.Toggle className="userRelationshipButton">
                                <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => confirmationHandler('unblock', e, 1, item.user_id)}>Desbloquear contato</Dropdown.Item>
                                <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )
        case 'not_friend':
            return (
                <>
                    {dataApplication.customizacao.qrcodeOnly === false && <div className="userRelationshipButton">
                        <Button variant="primary" className="me-2" onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)}>
                            <FontAwesomeIcon className="me-2" icon={faUserPlus} />
                            Adicionar
                        </Button>
                    </div>}
                    <div onClick={handleDropdownClick}>
                        <Dropdown>
                            <Dropdown.Toggle className="userRelationshipButton">
                                <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => confirmationHandler('block', e, 0, item.user_id)}>Bloquear contato</Dropdown.Item>
                                <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )
        case 'pending_request':
            return (
                <>
                    {dataApplication.customizacao.qrcodeOnly === false && <div className="userRelationshipButton">
                        <Button variant="secondary" className="me-2" onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)}>
                            <FontAwesomeIcon className="me-2" icon={faUserMinus} />
                            Cancelar convite
                        </Button>
                    </div>}
                    <div onClick={handleDropdownClick}>
                        <Dropdown>
                            <Dropdown.Toggle className="userRelationshipButton">
                                <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => confirmationHandler('block', e, 0, item.user_id)}>Bloquear contato</Dropdown.Item>
                                <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )
        case 'pending_acceptance':
            return (
                <>
                    {dataApplication.customizacao.qrcodeOnly === false && <div className="userRelationshipButton">
                        <Button variant="primary" className="me-2" onClick={(e) => friendRequest(e, 1, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)}>Aceitar convite</Button>
                        <Button variant="secondary" className="me-2" onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)}>Rejeitar</Button>
                    </div>}
                    <div onClick={handleDropdownClick}>
                        <Dropdown>
                            <Dropdown.Toggle className="userRelationshipButton">
                                <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => confirmationHandler('block', e, 0, item.user_id)}>Bloquear contato</Dropdown.Item>
                                <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            )

        default:
            return ("")
    }
};

const UserRelationshipButton = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();
    const { socket, setModalConfirmation, setConfirmationProps } = useAppState();

    const handleDropdownClick = (e) => {
        e.stopPropagation()
    };

    const confirmationHandler = (type, e, status, user_id) => {
        e.stopPropagation()
        let props = {}
        if (type === 'remove') {
            props.title = "Remover usuário"
            props.description = "Deseja remover esse usuário da sua lista de contatos? Você não poderá mais interagir com ele"
        } else if (type === 'block') {
            props.title = "Bloquear usuário"
            props.description = "Deseja bloquear esse usuário? Você será removido da lista de contatos dele"
        } else if (type === 'unblock') {
            props.title = "Desbloquear usuário"
            props.description = "Deseja desbloquear esse usuário? Você voltará a ficar disponível na lista de contatos dele"
        }
        setConfirmationProps({ 'type': type, 'title': props.title, 'description': props.description, 'user_id': user_id, 'status': status })
        setModalConfirmation(true)
    };

    switch (item.relationship_status) {
        case 'friend':
            return (
                <div onClick={handleDropdownClick}>
                    <Dropdown>
                        <Dropdown.Toggle className="userRelationshipButton">
                            <FontAwesomeIcon className="iconsHover dropdown-toggle" size={'lg'} icon={faEllipsisVertical} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => confirmationHandler('remove', e, 0, item.user_id)}>Remover contato</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => confirmationHandler('block', e, 0, item.user_id)}>Bloquear contato</Dropdown.Item>
                            <Dropdown.Item disabled onClick={(e) => confirmationHandler('report', e, 0, item.user_id)}>Reportar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        case 'blocked':
            return (
                <div className="userRelationshipButton">
                    <FontAwesomeIcon className="iconsHover" size={'lg'} icon={faBan} onClick={(e) => confirmationHandler('unblock', e, 1, item.user_id)} />
                </div>
            )
        case 'not_friend':
            if (dataApplication.customizacao.qrcodeOnly === true) {
                return
            }
            return (
                <div className="userRelationshipButton">
                    <FontAwesomeIcon className="iconsHover" size={'lg'} icon={faUserPlus} onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts)} />
                </div>
            )
        case 'pending_request':
            if (dataApplication.customizacao.qrcodeOnly === true) {
                return
            }
            return (
                <div className="userRelationshipButton">
                    <FontAwesomeIcon className="iconsHover" size={'lg'} icon={faUserMinus} onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts)} />
                </div>
            )
        case 'pending_acceptance':
            if (dataApplication.customizacao.qrcodeOnly === true) {
                return
            }
            return (
                <div className="userRelationshipButton">
                    <FontAwesomeIcon className="iconsHover me-2" size={'lg'} icon={faTimesCircle} onClick={(e) => friendRequest(e, 0, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts)} />
                    <FontAwesomeIcon className="iconsHover" size={'lg'} icon={faCheckCircle} onClick={(e) => friendRequest(e, 1, item.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts)} />
                </div>
            )

        default:
            return ("")
    }
};

export const friendRequest = (e, newStatus, id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem) => {
    e.stopPropagation();
    let newRelationshipStatus = updateCurrentData(newStatus, id, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem, 'friendRequest')
    newRelationshipStatus.id = id
    newRelationshipStatus.origin = 'friendRequest'
    socket.emit('friendRequest', newRelationshipStatus);
};

export const blockRequest = (e, newStatus, id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem) => {
    e.stopPropagation();
    let newRelationshipStatus = updateCurrentData(newStatus, id, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem, 'blockRequest')
    newRelationshipStatus.id = id
    newRelationshipStatus.origin = 'blockRequest'
    socket.emit('blockRequest', newRelationshipStatus);
}

const getNewRelationshipStatus = (previusRelationshipStatus, newStatus, setTotalContacts, type) => {
    if (newStatus === 1) {
        //Accept the contact
        if (setTotalContacts) {
            setTotalContacts(prevState => ({
                ...prevState,
                total_invites: Math.max(0, parseInt(prevState.total_invites) - 1),
                total_contacts: Math.max(0, parseInt(prevState.total_contacts) + 1)
            }));
        }
        return { 'newStatus': 1, 'relationship_status': 'friend', remove: false, };
    } else {
        if (previusRelationshipStatus === 'friend') {
            //Remove or reject the contact
            if (setTotalContacts) {
                setTotalContacts(prevState => ({
                    ...prevState,
                    total_contacts: Math.max(0, parseInt(prevState.total_contacts) - 1)
                }));
            }
            return { 'newStatus': 0, 'relationship_status': 'not_friend', remove: true };
        } else if (previusRelationshipStatus === 'pending_acceptance' || previusRelationshipStatus === 'pending_request') {
            //Remove or reject the contact
            if (setTotalContacts) {
                setTotalContacts(prevState => ({
                    ...prevState,
                    total_invites: Math.max(0, parseInt(prevState.total_invites) - 1)
                }));
            }
            return { 'newStatus': 0, 'relationship_status': 'not_friend', remove: true };
        } else {
            //Invite the contact
            if (setTotalContacts) {
                setTotalContacts(prevState => ({
                    ...prevState,
                    total_invites: parseInt(prevState.total_invites) + 1
                }));
            }
            return { 'newStatus': 0, 'relationship_status': type ? type : 'pending_request', 'other_user_relationship_status': 'pending_acceptance', remove: false };
        }
    }
}

const getNewRelationshipStatusBlock = (previusRelationshipStatus, newStatus, setTotalContacts) => {
    let props
    if (newStatus === 1) {
        props = { 'newStatus': newStatus, 'relationship_status': 'not_friend' }
    } else {
        props = { 'newStatus': newStatus, 'relationship_status': 'blocked' }
    }
    if (setTotalContacts) {
        if (previusRelationshipStatus === 'friend' || previusRelationshipStatus === 'block') {
            //Remove or reject the contact
            setTotalContacts(prevState => ({
                ...prevState,
                total_contacts: Math.max(0, parseInt(prevState.total_contacts) - 1)
            }));
        } else if (previusRelationshipStatus === 'pending_acceptance' || previusRelationshipStatus === 'pending_request') {
            //Remove or reject the contact
            setTotalContacts(prevState => ({
                ...prevState,
                total_invites: Math.max(0, parseInt(prevState.total_invites) - 1)
            }));
        }
    }
    return props
}

export const updateCurrentData = (newStatus, id, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem, origin, type = undefined) => {
    let newRelationshipStatus

    if (pageDataItem) {
        //if element is open
        if (origin === 'friendRequest') {
            newRelationshipStatus = getNewRelationshipStatus(pageDataItem.relationship_status, newStatus, false, type)
        } else {
            newRelationshipStatus = getNewRelationshipStatusBlock(pageDataItem.relationship_status, newStatus, false)
        }
        setPageDataItem(prevState => ({
            ...prevState,
            relationship_status: newRelationshipStatus.relationship_status
        }));
    } else {
        if (pageData) {
            //For external pages
            if (pageData.networking) {
                const updatedEvents = pageData.networking.map(user => {
                    if (user.user_id === id) {
                        if (origin === 'friendRequest') {
                            newRelationshipStatus = getNewRelationshipStatus(user.relationship_status, newStatus, false, type)
                        } else {
                            newRelationshipStatus = getNewRelationshipStatusBlock(user.relationship_status, newStatus, false)
                        }
                        return {
                            ...user,
                            relationship_status: newRelationshipStatus.relationship_status

                        };
                    }
                    return user;
                })
                setPageData(prevState => ({
                    ...prevState,
                    networking: updatedEvents
                }));
            } else {
                //Within the pages
                const updatedEvents = pageData.map(user => {
                    if (user.user_id === id) {
                        if (origin === 'friendRequest') {
                            newRelationshipStatus = getNewRelationshipStatus(user.relationship_status, newStatus, setTotalContacts, type)
                        } else {
                            newRelationshipStatus = getNewRelationshipStatusBlock(user.relationship_status, newStatus, setTotalContacts)
                        }
                        return {
                            ...user,
                            relationship_status: newRelationshipStatus.relationship_status

                        };
                    }
                    return user;
                })
                setPageData(updatedEvents);
                if (pageDataComplete) {
                    const updatedEvents2 = pageDataComplete.map(user => {
                        if (user.user_id === id) {
                            return {
                                ...user,
                                relationship_status: newRelationshipStatus.relationship_status

                            };
                        }
                        return user;
                    })
                    setPageDataComplete(updatedEvents2);
                }
            }
        }
    }
    return newRelationshipStatus
}

export default Networking