/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Collapse } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { NavLink, useNavigate } from "react-router-dom";
import useOuterClick from "../hooks/useOuterClick";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useLocation } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from '../components/RouteDebouncer.js';
import { useGlobalState } from "../hooks/useInitialization";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { getIcon } from '../components/Functions.js'

const homepage = {
    "href": "/",
    "icon": "faHouse",
    "title": "Página inicial",
    "displayOnFooter": true
}

const lead = {
    href: "/coleta-de-lead",
    icon: "faAddressCard",
    title: "Coleta de lead",
    displayOnFooter: true,
}

const Sidebar = () => {
    const { dataApplication } = useGlobalState()
    const { isOpen, setIsOpen } = useSidebar();
    const { user } = useAuth()
    const combinedSections = useRef([])

    const innerRef = useOuterClick(() => {
        if (window.innerWidth <= 769 && isOpen) {
            setIsOpen(false)
        }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkWidthAndUpdateSidebarState = () => {
        if (window.innerWidth <= 769) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", checkWidthAndUpdateSidebarState);
        return () => {
            window.removeEventListener("resize", checkWidthAndUpdateSidebarState);
        };
    }, [checkWidthAndUpdateSidebarState]);


    useEffect(() => {
        combinedSections.current.push(homepage);
        if (user.type === 1) {
            combinedSections.current.push(lead);
            if (dataApplication.customizacao.sections && dataApplication.customizacao.sections.length > 0) {
                combinedSections.current = [...combinedSections.current, ...dataApplication.customizacao.sections];
            }
        } else {
            combinedSections.current = [dataApplication.customizacao.sections];
        }
    }, []);
 
    if (combinedSections.current.length === 0) {
        return ""
    }

    return (
        <section className={`sidebar ${isOpen ? "collapsed" : ""}`} ref={innerRef}>
            <div className="sidebar-content">
                <PerfectScrollbar>
                    <p className="fw-bold text-center">Menu</p>
                    <ul className="sidebar-nav">
                        {combinedSections.current.map((item, i) => (
                            <SidebarNavItem key={'navItem' + i} item={item} />
                        ))}
                    </ul>
                </PerfectScrollbar>
            </div>
        </section>
    );
}

const SidebarNavItem = ({ item }) => {
    const location = useLocation();
    const { isOpen, setIsOpen } = useSidebar();
    const [open, setOpen] = useState();
    const icon = getIcon(item.icon);
    const navigate = useNavigate();

    const debouncedNavigate = useDebounce((path, state) => {
        navigate(path + location.search, { state: { props: state } });
    }, 250);

    const handleClick = (e, path, state) => {
        e.preventDefault();
        debouncedNavigate(path, state);
    };

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    const handleSidebar = () => {
        if (isOpen) {
            if (window.innerWidth <= 769) {
                setIsOpen(!isOpen);
            }
        }
    };

    if (item.children) {
        return (
            <li className={`sidebar-item ${open ? "active" : ""}`}>
                <a className={`${open ? "" : "collapsed"} sidebar-link`} data-bs-toggle="collapse" aria-expanded={open ? "true" : "false"} onClick={handleToggle}>
                    <FontAwesomeIcon icon={item.icon} className="me-2" size="lg" />
                    <span>{item.title}</span>
                    <motion.div
                        animate={{ rotate: open ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                        className="ms-2 float-end"
                    >
                        <FontAwesomeIcon icon={faChevronDown} size={'sm'} />
                    </motion.div>
                </a>
                <Collapse in={open}>
                    <ul className="sidebar-dropdown list-unstyled">
                        {item.children.map((props, ip) => (
                            <li key={'sidebar-item-children' + ip}>
                                <a
                                    onClick={(e) => handleClick(e, props.item.href)}
                                    className={`${location.pathname === props.item.href ? 'active' : ''} sidebar-link`}
                                >
                                    <span>{props.title}</span>
                                </a>
                            </li>
                        ))
                        }
                    </ul>
                </Collapse>
            </li>
        );
    }

    return (
        <li className="sidebar-item">
            {item.external_link ? (
                <a className="sidebar-link" target="_blank" rel="noopener noreferrer" href={item.href}>
                    <span>
                        <FontAwesomeIcon icon={icon} className="me-2" size="lg" />
                        {item.title}
                    </span>
                </a>
            ) : (
                <a
                    className={`${location.pathname === item.href ? 'active' : ''} ${item.displayOnFooter ? " displayOnFooter" : ""} sidebar-link`}
                    onClick={(e) => {
                        e.preventDefault();
                        debouncedNavigate(item.href, item);
                        handleSidebar()
                    }}>
                    <span>
                        <FontAwesomeIcon icon={icon} className="me-2" size="lg" />
                        {item.title}
                    </span>
                </a>
            )}
        </li>
    );
}

export default Sidebar