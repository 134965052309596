import { useState, useEffect, useRef } from 'react'
import { Button, Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap'
import { useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faRefresh, faAward } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../hooks/useInitialization.js';
import { useAppState } from '../../hooks/useApp'
import PageLoader from '../../components/PageLoader'
import achievement01 from '../../assets/img/achievement01.png'
import achievement02 from '../../assets/img/achievement02.png'
import achievement03 from '../../assets/img/achievement03.png'
import achievement04 from '../../assets/img/achievement04.png'

const Ranking = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pageData, setPageData] = useState()
    const [tabKey, setTabKey] = useState('ranking');
    const [updateData, setUpdateData] = useState(0);
    const tabSwitching = useRef(false)
    const tabKeyRef = useRef('ranking')

    const handleRefresh = () => {
        setPageData();
        setUpdateData((prevState) => prevState + 1)
    }

    const handleTabSwitch = (tab) => {
        if (tabKeyRef.current === tab) {
            return
        }
        if (tabSwitching.current === false) {
            setTabKey(tab);
            setPageData()
        }
    }

    return (
        <motion.div className="container rankingContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Gamificação</h5></li>
                    </ol>
                </nav>
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Atualizar ranking</Tooltip>}>
                    <Button type="button" className="backButton btn-sm ms-auto" onClick={() => handleRefresh()}>
                        <FontAwesomeIcon icon={faRefresh} size={"lg"} />
                    </Button>
                </OverlayTrigger>
            </header>
            <div className="header-wrapper mb-3">
                <div className="tab-wrapper">
                    <motion.div
                        className="switch-background"
                        initial={false}
                        animate={{ x: tabKey === 'ranking' ? 0 : '100%' }}
                        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    />
                    <button
                        className={`tab-button ${tabKey === 'ranking' ? 'active' : ''}`}
                        onClick={() => handleTabSwitch('ranking')}
                    >
                        Placar de líderes
                    </button>
                    <button
                        className={`tab-button ${tabKey === 'achievements' ? 'active' : ''}`}
                        onClick={() => handleTabSwitch('achievements')}
                    >
                        Conquistas
                    </button>
                </div>
            </div>
            <AnimatePresence mode="wait" initial={false}>
                {tabKey === 'achievements' ? (
                    <AchievementList key={'itemA'} pageData={pageData} setPageData={setPageData} updateData={updateData} tabKeyRef={tabKeyRef} tabKey={tabKey}></AchievementList>
                ) : (
                    <RankingList key={'itemB'} pageData={pageData} setPageData={setPageData} updateData={updateData} tabSwitching={tabSwitching} tabKeyRef={tabKeyRef} tabKey={tabKey} ></RankingList>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

export const RankingList = ({ pageData, setPageData, updateData, tabSwitching, tabKeyRef, tabKey }) => {
    const { dataApplication } = useGlobalState();
    const { socket, dispatch } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();

    const handleCardClick = (item) => {
        navigate(`/participantes/${item.user_id}${location.search}`);
        dispatch({ type: 'updatePageDataItem' });
    }

    useEffect(() => {
        const fetchData = () => {
            if (socket) {
                socket.emit('pageDataRanking', { page: 'ranking' });
            }
        };

        // Set up socket listener
        if (socket) {
            tabSwitching.current = true
            tabKeyRef.current = tabKey

            socket.on('pageDataRanking', (response) => {
                setTimeout(() => {
                    setPageData(response);
                    dispatch({ type: 'ranking', payload: response });
                    tabSwitching.current = false
                }, 250)
            });

            // Emit initial request
            fetchData();
            // Set up interval to fetch data every 15 seconds
            const interval = setInterval(fetchData, 15000);

            // Cleanup on component unmount
            return () => {
                clearInterval(interval);
                socket.off('pageDataRanking');
            };
        }

        // Cleanup function to clear the interval if socket is not available
        return () => clearInterval(15000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, dispatch, updateData]);

    // Rearrange top three to be in the order: third, first, second

    if (!pageData) {
        return (
            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
            </motion.div>
        )
    }

    const topThree = pageData.slice(0, 3);
    const restOfData = pageData.slice(3);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <div className="podium">
                <div className="spot second">
                    <div className="cardProfile">
                        <Card className="card-body pointer" onClick={() => handleCardClick(topThree[1])}>
                            <img className="topRankingPics" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + topThree[1].image} alt={topThree[1].name} />
                            <div>
                                <h6 className="my-2 small fw-bold truncate-multiline">
                                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                        if (topThree[1].json && topThree[1].json[field.inputID]) {
                                            if (index === 0) {
                                                return topThree[1].json[field.inputID].value.substring(0, 35);
                                            }
                                        }
                                        return null; // Handle cases where the field is not found (optional)
                                    })}
                                </h6>
                            </div>
                            <h5 className="fw-bold mb-0">
                                <FontAwesomeIcon icon={faAward} className="me-2 mb-0" />
                                {topThree[1].total_value}
                            </h5>
                        </Card>
                    </div>
                    <div className="place"><span>2</span></div>
                </div>
                <div className="spot first">
                    <div className="cardProfile">
                        <Card className="card-body pointer" onClick={() => handleCardClick(topThree[0])}>
                            <img className="topRankingPics" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + topThree[0].image} alt={topThree[0].name} />
                            <h6 className="my-2 small fw-bold truncate-multiline">
                                {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                    if (topThree[0].json && topThree[0].json[field.inputID]) {
                                        if (index === 0) {
                                            return topThree[0].json[field.inputID].value.substring(0, 35);
                                        }
                                    }
                                    return null; // Handle cases where the field is not found (optional)
                                })}
                            </h6>
                            <h5 className="fw-bold mb-0">
                                <FontAwesomeIcon icon={faAward} className="me-2 mb-0" />
                                {topThree[0].total_value}
                            </h5>
                        </Card>
                    </div>
                    <div className="place"><span>1</span></div>
                </div>
                <div className="spot third">
                    <div className="cardProfile">
                        <Card className="card-body pointer" onClick={() => handleCardClick(topThree[2])}>
                            <img className="topRankingPics" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + topThree[2].image} alt={topThree[2].name} />
                            <h6 className="my-2 small fw-bold truncate-multiline">
                                {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                    if (topThree[2].json && topThree[2].json[field.inputID]) {
                                        if (index === 0) {
                                            return topThree[2].json[field.inputID].value.substring(0, 35);
                                        }
                                    }
                                    return null; // Handle cases where the field is not found (optional)
                                })}
                            </h6>
                            <h5 className="fw-bold mb-0">
                                <FontAwesomeIcon icon={faAward} className="me-2 mb-0" />
                                {topThree[2].total_value}
                            </h5>
                        </Card>
                    </div>
                    <div className="place"><span>3</span></div>
                </div>
            </div>
            {restOfData && (Array.isArray(restOfData)) && restOfData.length > 0 ? (
                <Row>
                    {restOfData.map((item, index) => (
                        <Col sm={12} md={6} key={'rankingCard' + index}>
                            <div className="card pointer mb-3" onClick={() => handleCardClick(item)}>
                                <div className="rankingCard">
                                    <div className="horizontally-centered side-element">
                                        <span className="h6 fw-bold mb-0">{index + 4}</span>
                                        <img className="ms-3" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + item.image} alt={item.name} />
                                    </div>
                                    <div className="message">
                                        <p className="mb-0 small truncate-multiline">
                                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                if (item.json && item.json[field.inputID]) {
                                                    if (index === 0) {
                                                        return item.json[field.inputID].value
                                                    }
                                                }
                                                return null; // Handle cases where the field is not found (optional)
                                            })}
                                        </p>
                                    </div>
                                    <div className="side-element">
                                        <p className="mb-0 small truncate-multiline">{item.total_value} pts</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Row className="mb-3">
                    <Col className="text-center mt-4" sm={12}>
                        <p className="opacity-75">Nenhum participante encontrado</p>
                    </Col>
                </Row>
            )}
        </motion.div>
    )
}

export const AchievementList = ({ pageData, setPageData, updateData, tabKeyRef, tabKey }) => {
    const { dataApplication } = useGlobalState();
    const { socket, dispatch } = useAppState()

    function getAchievementIcon(image) {
        switch (image) {
            case 'events':
                return achievement01
            case 'social':
                return achievement02
            case 'networking':
                return achievement03

            default:
                return achievement04
        }
    }

    useEffect(() => {
        if (socket) {
            tabKeyRef.current = tabKey
            socket.on('pageDataAchievements', (response) => {
                setPageData(response);
                dispatch({ type: 'achievements', payload: response });
            });

            socket.emit('pageDataAchievements', { page: 'achievements' });

            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataAchievements');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, updateData]);

    if (!pageData) {
        return (
            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
            </motion.div>
        )
    }
    return (
        <Row className="pt-3">
            {pageData.map((item, index) => (
                <Col sm={12} md={6} key={'achievementCard' + index}>
                    <div className={`achievementCard ${item.collected > 0 ? "" : "notCollected"}`} data-label={'Coletado'}>
                        <div className="card mb-3">
                            <div className="card-body pointer">
                                <div className="side-element">
                                    <img className="achievement-img" src={getAchievementIcon(item.json.image)} alt={item.json.title} />
                                </div>
                                <div className="message">
                                    <h6 className="fw-bold">{item.json.title}</h6>
                                    <p className="mb-0 small truncate-multiline"><h6>{item.json.description}</h6></p>
                                    <p className="mb-0 small">
                                        <span>
                                            <FontAwesomeIcon icon={faAward} size={'lg'} className="primaryColorT me-1" />
                                            {item.value} pts
                                        </span>
                                        {item.ready_to_collect > 0 && !item.collected > 0 ?
                                            <motion.button
                                                className="btn btn-primary btn-sm float-end shining-button"
                                                animate={{ scale: [0.95, 1, 0.95] }}
                                                transition={{ duration: 1, repeatDelay: 1, repeat: Infinity, ease: "easeInOut", delay: 1.5 }}>Resgatar
                                            </motion.button>
                                            : item.type === 2 ? <span className="float-end">{item.current_value > 0 ? item.current_value : 0}/{item.required_value}</span> : ''}
                                    </p>
                                </div>
                            </div>
                            {item.ready_to_collect > 0 && !item.collected > 0 && <div className="shining-border"></div>}
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}
export default Ranking