import React, { useRef, useEffect } from 'react'
import { motion, LayoutGroup, } from 'framer-motion'
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSidebar from "../hooks/useSidebar";
import useAuth from '../hooks/useAuth';
import { useDebounce } from '../components/RouteDebouncer.js';
import { useGlobalState } from "../hooks/useInitialization";
import { getIcon } from '../components/Functions.js'
import { icon } from '@fortawesome/fontawesome-svg-core';

const homepage = {
    href: "/",
    icon: "faHouse",
    title: "Página inicial",
    displayOnFooter: true
}
const menu = {
    href: "/menu",
    icon: "faBars",
    title: "Menu",
    displayOnFooter: true
};
const qrcode = {
    href: "/qrcode",
    icon: "faQrcode",
    title: "QR Code",
    displayOnFooter: true
};

const lead = {
    href: "/coleta-de-lead",
    icon: "faAddressCard",
    title: "Coleta de lead",
    displayOnFooter: true,
}

const Footer = () => {
    const { dataApplication } = useGlobalState()
    const { user } = useAuth()
    const combinedSections = useRef([])

    useEffect(() => {
        combinedSections.current.push(homepage);
        if (dataApplication.customizacao.sections && dataApplication.customizacao.sections.length > 0) {
            let totalDisplayOnFooter = []
            dataApplication.customizacao.sections.forEach(element => {
                if (element.displayOnFooter === true) {
                    totalDisplayOnFooter.push(element)
                }
            });
            combinedSections.current = [...combinedSections.current, ...totalDisplayOnFooter];
        }
        if (user.type === 1) {
            combinedSections.current.push(lead);
        }
        console.log(combinedSections.current)
        let totalNotDisplayOnFooter = []
        dataApplication.customizacao.sections.forEach(element => {
            if (!element.displayOnFooter) {
                totalNotDisplayOnFooter.push(element)
            }
        });
        console.log(totalNotDisplayOnFooter)
        if (totalNotDisplayOnFooter.length > 0) {
            combinedSections.current.push(menu);
        }
        const middleIndex = Math.floor(combinedSections.current.length / 2);
        if (middleIndex > 0) {
            combinedSections.current.splice(middleIndex, 0, qrcode);
        } else {
            combinedSections.current.push(qrcode);
        }
    }, []);

    if (combinedSections.current.length === 0) {
        return ""
    }

    return (
        <footer className="footer">
            <ul className="nav nav-pills nav-fill">
                <LayoutGroup id="a">
                    {combinedSections.current.map((item, i) => (
                        <FooterNavItem key={'navItem' + i} item={item} />
                    ))}
                </LayoutGroup>
            </ul>
        </footer>
    )
}

const FooterNavItem = ({ item }) => {
    const { isOpen, setIsOpen } = useSidebar();
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const location = useLocation();
    const debouncedNavigate = useDebounce((path, props = undefined) => {
        navigate({
            pathname: path,
            search: location.search,
            state: props,
        });
    }, 250);
    const icon = getIcon(item.icon);

    const handleSidebar = () => {
        if (isOpen) {
            if (window.innerWidth <= 769) {
                setIsOpen(!isOpen);
            }
        }
    };

    if (item.displayOnFooter === true) {
        return (
            <motion.li className="nav-item" onClick={() => {
                debouncedNavigate(item.href)
                handleSidebar()
            }}>
                <span className={item.href === pathname ? 'active' : ''}>
                    <FontAwesomeIcon icon={icon} size="lg" />
                    {(item.href === pathname) && <motion.div
                        className="underline"
                        layoutId="underline"
                    />}
                </span>
            </motion.li>
        )
    } else {
        return (
            <motion.li className="nav-item" onClick={() => {
                setIsOpen((prevState) => !prevState);
            }}>
                <span className={(item.href === pathname && item.displayOnFooter === false) ? 'active' : ''}>
                    <FontAwesomeIcon icon={icon} size="lg" />
                    {(item.href === pathname && item.displayOnFooter === false) && <motion.div
                        className="underline"
                        layoutId="underline"
                    />}
                </span>
            </motion.li>
        )
    }
}

export default Footer