import { useState, useEffect, useRef, useContext } from 'react'
import { Button, OverlayTrigger, Form, Tabs, Tab, Tooltip, Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faTrophy, faAward, faFileExport } from '@fortawesome/free-solid-svg-icons'
import { Formik } from "formik"
import * as Yup from "yup";
import NotyfContext from "../../contexts/NotyfContext";
import { useGlobalState } from '../../hooks/useInitialization.js';
import { useAppState } from '../../hooks/useApp'
import PageLoader from '../../components/PageLoader'
import { cleanUpSpecialChars, useInfiniteScroll, TextWithLineBreaks, getExcelFromObject } from "../../components/Functions"
import useAuth from '../../hooks/useAuth.js';

const LeadCollection = () => {
    const { dataApplication } = useGlobalState();
    const { scrollContainerRef, socket, totalContacts, setTotalContacts } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const tabKeyRef = useRef('all')
    const [tabKey, setTabKey] = useState('collected');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)
    const tabSwitching = useRef(false)
    const notyf = useContext(NotyfContext);
    const [updateUserContact, setUpdateUserContact] = useState(false)
    const { user } = useAuth()
    const [pageFetching, setPageFetching] = useState(false)
    const pagination = useRef(0)
    const limit = 25;

    const handleTabSwitch = (tab) => {
        if (tabKeyRef.current === tab) {
            return
        }
        if (tabSwitching.current === false) {
            setTabKey(tab);
            setPageData()
            setPageDataComplete()
            setPageFetching(false)
            pagination.current = 0
        }
    }

    //Search function
    const search = (data) => {
        // eslint-disable-next-line eqeqeq
        if (searchQuery.trim() != '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
            let newData = data.filter(element => {
                let searchableFields = []
                dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.forEach(inputID => {
                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                    if (element.json && element.json[field.inputID]) {
                        searchableFields.push(cleanUpSpecialChars(element.json[field.inputID].value.toLowerCase()))
                    }
                })
                return searchableFields.some(field => field.includes(lowercaseQuery));
            })
            return newData
        } else {
            return data
        }
    }

    const fetchData = (addItems) => {
        socket.emit('pageDataLeadCollection', { page: 'leadCollection', tab: tabKey, id: id, offset: pagination.current * limit, addItems: addItems });
    }

    const exportLead = () => {
        socket.emit('leadCollectionExport');
    }

    const handleSubmitLead = (values, actions) => {
        setUpdateUserContact(true)
        console.log(values)
        socket.emit('editLeadCollection', { json: JSON.stringify(values) });
    }

    //Infinity scroll handler
    useInfiniteScroll(scrollContainerRef, pageFetching, setPageFetching, pagination, fetchData);

    useEffect(() => {
        if (pageData) {
            let newData = search(pageDataComplete)
            setPageData(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    useEffect(() => {
        if (socket && !pageDataItem) {
            tabSwitching.current = true
            tabKeyRef.current = tabKey

            socket.on('pageDataLeadCollection', (response) => {
                if (id && response.user_id) {
                    setPageDataItem(response);
                } else {
                    setTimeout(() => {
                        let updatedPageDataComplete;
                        if (response.addItems === true) {
                            setTimeout(() => {
                                if (response.data.length > 0) {
                                    setPageDataComplete(prevPageDataComplete => {
                                        if (Array.isArray(prevPageDataComplete)) {
                                            updatedPageDataComplete = [...prevPageDataComplete, ...response.data];
                                            const searchedData = search(updatedPageDataComplete);
                                            setPageData(searchedData);
                                            return updatedPageDataComplete
                                        } else {
                                            updatedPageDataComplete = [...response.data];
                                            const searchedData = search(updatedPageDataComplete);
                                            setPageData(searchedData);
                                            return prevPageDataComplete
                                        }
                                    });
                                    setPageFetching(false)
                                    pagination.current += 1;
                                } else {
                                    setPageFetching('noFetching')
                                }
                            }, 250);
                        } else {
                            //Normal fetch
                            setPageData(response.data);
                            setPageDataComplete(response.data)
                            //Check if infinity scroll is neccessary and set it up
                            if (pagination.current === 0 && response.data.length >= limit) {
                                pagination.current += 1;
                            } else {
                                setPageFetching('noFetching')
                            }
                        }
                        setTotalContacts(response.total);
                        //Tab debounce
                        tabSwitching.current = false;
                    }, 250);
                }
            });

            socket.on('leadCollectionExport', (response) => {
                let headers = []
                let data = response.data
                dataApplication.customizacao.form.fields.forEach((element) => {
                    headers.push({ value: element.label, inputID: element.inputID });
                });
                getExcelFromObject({ filename: dataApplication.title, headers, data });
            });

            fetchData(false)

            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataLeadCollection');
                socket.off('leadCollectionExport');
                socket.off('editLeadCollection');
            };
        }
        if (socket && pageDataItem) {
            console.log('editLeadCollection')
            socket.on('editLeadCollection', (result) => {
                console.log(result)
                console.log('asuuuu')
                setUpdateUserContact(false)
                notyf.open({
                    type: "success",
                    message: 'Observação adicionada com sucesso',
                    ripple: true,
                    dismissible: true,
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, id, tabKey]);

    return (
        <motion.div className="container leadCollectionContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <AnimatePresence mode="wait" initial={false}>
                {id > 0 ? (
                    <motion.div key={'itemA'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <header className="pageHeader">
                            <Button type="button" className="backButton" onClick={(() => navigate(`/coleta-de-lead${location.search}`))}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Button>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ms-2">
                                    <li className="breadcrumb-item"><h5 onClick={(() => navigate(`/coleta-de-lead${location.search}`))}>Coleta de lead</h5></li>
                                    <li className="breadcrumb-item"><h5>Detalhes</h5></li>
                                </ol>
                            </nav>
                        </header>
                        {(!pageDataItem) ? (
                            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                            </motion.div>
                        ) : (
                            <>
                                <div className='networkingItemHeader'>
                                    <div className="networkingRankingLeft">
                                        <FontAwesomeIcon icon={faTrophy} className="me-2" />{pageDataItem.rank_position}º
                                    </div>
                                    <div className="networkingRankingRight">
                                        <FontAwesomeIcon icon={faAward} className="ms-4 me-2" />{pageDataItem.total_value} pts
                                    </div>
                                    <label>
                                        <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + pageDataItem.image} alt="User avatar"></img>
                                    </label>
                                </div>
                                <Row className="networkingItemBody">
                                    <Col sm={12} className="mb-3">
                                        {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                            if (pageDataItem.json && pageDataItem.json[field.inputID]) {
                                                if (index === 0) {
                                                    return (
                                                        <h6 className="mb-0" key={'h5' + field.inputID}>{pageDataItem.json[field.inputID].value}</h6>
                                                    )
                                                } else {
                                                    return (
                                                        <p className="mt-1 mb-0 small" key={'small' + field.inputID}>{pageDataItem.json[field.inputID].value}</p>
                                                    )
                                                }
                                            }
                                            return null; // Handle cases where the field is not found (optional)
                                        })}
                                    </Col>
                                    <Col sm={12} className="Card mb-3 d-flex justify-content-center">
                                        <Formik
                                            validationSchema={Yup.object().shape({
                                                observacao: Yup.string()
                                                    .required("Campo obrigatório")
                                                    .max(255, `Máximo de 1000 caracteres`)
                                            })}
                                            onSubmit={handleSubmitLead}
                                            validateOnChange={false}
                                            validateOnBlur={true}
                                            enableReinitialize
                                            initialValues={{
                                                observacao: pageDataItem.json_collected && pageDataItem.json_collected.observacao,
                                                id: pageDataItem.user_id
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                touched,
                                                isValid,
                                                errors,
                                                dirty,
                                                isSubmitting,
                                                setSubmitting,
                                                setFieldValue,
                                                setFieldError
                                            }) => (
                                                <Form noValidate onSubmit={handleSubmit} className="w-100 text-start">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Observação</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            type="text"
                                                            name="observacao"
                                                            value={values.observacao}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.observacao}
                                                            isValid={touched.observacao && !errors.observacao}
                                                        />
                                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.observacao}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <motion.div
                                                        key="accessCodeGranted2"
                                                        className="text-center"
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: 'auto', opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.5 }}
                                                        style={{ overflow: 'hidden' }}
                                                    >
                                                        <Button
                                                            variant="primary"
                                                            id="submitButton"
                                                            type={'submit'}
                                                            disabled={updateUserContact}
                                                        >
                                                            {!updateUserContact ? 'Salvar' : <PageLoader color="#fff" width="25" padding={0} />}
                                                        </Button>
                                                    </motion.div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                                <Tabs
                                    defaultActiveKey="info"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mt-4 mb-3"
                                >
                                    <Tab eventKey="info" title="Sobre">
                                        {pageDataItem.relationship_status === 'friend' || pageDataItem.collected === 1 ? (
                                            <>
                                                {Object.entries(pageDataItem.json).map(([key, { label, value }]) => {
                                                    // eslint-disable-next-line eqeqeq
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID == key);
                                                    if (field && field.displayField === true) {
                                                        return (
                                                            <Col sm={12} className="mt-3" key={'modalDataFields' + key}>
                                                                <h6 className="fw-bold">{label}</h6>
                                                                <p><TextWithLineBreaks text={value}></TextWithLineBreaks></p>
                                                            </Col>
                                                        );
                                                    } else {
                                                        return ""
                                                    }
                                                })}
                                            </>
                                        ) : (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Adicione esse contato para visualizar suas informações.</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Tab>
                                    <Tab eventKey="publications" title="Publicações">
                                        {pageDataItem.relationship_status === 'friend' || pageDataItem.collected === 1 ? (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Nenhuma publicação encontrada</p>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Adicione esse contato para visualizar suas informações.</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Tab>
                                </Tabs>
                            </>
                        )}
                    </motion.div>
                ) : (
                    <motion.div key={'itemB'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <header className="pageHeader">
                            <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                                <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                            </Button>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ms-2">
                                    <li className="breadcrumb-item"><h5>Coleta de lead</h5></li>
                                </ol>
                            </nav>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Exportar dados coletados</Tooltip>}>
                                <Button type="button" className="backButton btn-sm ms-auto" onClick={() => exportLead()}>
                                    <FontAwesomeIcon icon={faFileExport} size={"lg"} />
                                </Button>
                            </OverlayTrigger>
                        </header>
                        <div className="header-wrapper mb-3">
                            <div className="tab-wrapper">
                                <motion.div
                                    className="switch-background"
                                    initial={false}
                                    animate={{ x: tabKey === 'collected' ? 0 : '100%' }}
                                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                                />
                                <button
                                    className={`tab-button ${tabKey === 'collected' ? 'active' : ''}`}
                                    onClick={() => handleTabSwitch('collected')}
                                >
                                    Registrados ({totalContacts.total_contacts})
                                </button>
                                <button
                                    className={`tab-button ${tabKey === 'notCollected' ? 'active' : ''}`}
                                    onClick={() => handleTabSwitch('notCollected')}
                                >
                                    Não registrados ({totalContacts.total_available})
                                </button>
                            </div>
                        </div>
                        <AnimatePresence mode="wait" initial={false}>
                            <PageHandler key={tabKey} tabKey={tabKey} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts}></PageHandler>
                        </AnimatePresence>
                        {pageFetching !== 'noFetching' && <motion.div className={`container p-0 pageLoaderContainer ${pageFetching === true ? 'opacity-1' : 'opacity-0'}`} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                            <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                        </motion.div>}
                    </motion.div>
                )
                };
            </AnimatePresence >
        </motion.div >
    );
}

const PageHandler = ({ tabKey, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {

    switch (tabKey) {
        case 'collected':
            return (
                <CollectedContacts pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} />
            )
        default:
            return (
                <NotCollectedContacts pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} />
            )
    }
}

const CollectedContacts = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row>
                            {pageData.map((item, index) => (
                                <Col md={4} sm={6} lg={3} className="mb-3 col-6 networking-card" key={'cardSubItem' + index}>
                                    <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} origin={'networking'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum participante encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

const NotCollectedContacts = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts }) => {
    const { dataApplication } = useGlobalState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row>
                            {pageData.map((item, index) => (
                                <Col md={4} sm={6} lg={3} className="mb-3 col-6 networking-card" key={'cardSubItem' + index}>
                                    <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} setTotalContacts={setTotalContacts} origin={'networking'} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum participante encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

export const ParticipantsCard = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, setTotalContacts, origin }) => {
    const { dataApplication } = useGlobalState();
    const { dispatch } = useAppState()
    const navigate = useNavigate();
    const { user } = useAuth()
    const location = useLocation();

    const handleCardClick = (item) => {
        if (origin === 'networking') {
            navigate(`/coleta-de-lead/${item.user_id}${location.search}`, { state: { item: item } });
        } else {
            navigate(`/coleta-de-lead/${item.user_id}${location.search}`);
        }
        dispatch({ type: 'updatePageDataItem' });
    }

    return (
        <div className={`card pointer ${item.collected === 0 ? 'not-collected' : ''}`} onClick={() => handleCardClick(item)}>
            <div className="card-body vertically-centered">
                <div class="w-100">
                    <img className="networkingCollectionProfile" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + '/' + item.image} alt="speakerImage" />
                </div>
                <div className="networkingFields mt-3">
                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                        if (item.json && item.json[field.inputID]) {
                            if (index === 0) {
                                return (
                                    <h6 key={'networkingFields' + index} className="card-title mb-0 fw-bold">{item.json[field.inputID].value}</h6>
                                )
                            } else {
                                return (
                                    <p className="mt-1 mb-0 small" key={'networkingFields' + index}>{item.json[field.inputID].value}</p>
                                )
                            }
                        }
                        return null; // Handle cases where the field is not found (optional)
                    })}
                    {item.collected === 1 && item.json_collected && <p className="mt-2 mb-0 small"><i>{item.json_collected.observacao}</i></p>}
                </div>
            </div>
        </div>
    )
}

export default LeadCollection